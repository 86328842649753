import profile1 from "../../assets/profile1.jpg";
import { education, Experence, portfolioData, skills } from "./data";

import "./index.scss";

interface props {
  selectedTab: number;
}

const Content = ({ selectedTab }: props) => {
  return (
    <div className="content">
      {selectedTab === 0 && (
        <>
          <div className="content-1">
            <h3 className="content-1-heading">About Me</h3>
            <div className="content-1-des">
              <h1>Full Stack Developer</h1>
              <h1>Web, Mobile & Desktop</h1>
            </div>
            <h5 className="content-1-details">
              Self-motivated developer adds a high level of experience over more
              than 5+ years of working on multiple web-based projects.
              Passionate, hardworking coder with a penchant for developing
              customized interfaces that factor in unique demands for
              accessibility, reachability, and security. An organized approach
              to meeting multiple, concurrent deadlines. Pulls from active
              knowledge of the current technology landscape to promote best
              practices in web development.
            </h5>
            <h5 className="content-1-details">
              5+ years of experience, Practise makes man perfect, and experience
              has got me beyond perfection. With 5+ years of experience and 50+
              commercial projects' accomplishment and high level of cognition in
              the projects I work, I have excelled in my passion. My knowledge
              on latest web technologies and advanced techniques and methods
              adds benefit to my experience in my profession.
            </h5>
            <a
              href="https://joshuadaniel.me/Daniel.pdf"
              target="_blank"
              className="resume"
              rel="noreferrer"
            >
              DOWNLOAD CV
            </a>
          </div>
          <div className="content-1">
            <div className="content-1-div">
              <div className="content-1-data">
                <h6>Name :</h6>
                <h4>Daniel Paul Joshuah</h4>
              </div>
              <div className="content-1-data">
                <h6>Birthday :</h6>
                <h4>2nd April 1999</h4>
              </div>
              <div className="content-1-data">
                <h6>Email :</h6>
                <h4>danielpauljoshuas@gmail.com</h4>
              </div>
              <div className="content-1-data">
                <h6>Phone :</h6>
                <h4>+91-6361628618</h4>
              </div>
              <div className="content-1-data">
                <h6>Adress :</h6>
                <h4>Bangalore, Karnataka, India</h4>
              </div>
              <div className="content-1-data">
                <h6>Nationality :</h6>
                <h4>Indian</h4>
              </div>
              <div className="content-1-data">
                <h6>LinkedIn :</h6>
                <a href="https://www.linkedin.com/in/danielpauljoshuas/">
                  Click Here
                </a>
              </div>
              <img src={profile1} alt="profile" className="content-1-img" />
            </div>
          </div>
        </>
      )}
      {selectedTab === 1 && (
        <>
          <div className="content-two">
            <h3 className="content-1-heading">Resume</h3>
            <h1>Combination of Skill & Experience</h1>
            <div className="content-two-flex">
              <div className="content-two-info">
                <h2 className="flex">
                  <div className="circle"></div> Experience
                </h2>
                <br />
                {Experence.map((ex, i) => (
                  <div key={i} className="content-two-wrapper">
                    <div className="content-two-title">
                      <div>
                        <h3>{ex.title}</h3>
                        <h4>{ex.company}</h4>
                      </div>
                      <div>
                        <h5>{ex.date}</h5>
                      </div>
                    </div>
                    <br />
                    <div className="content-two-content">
                      <ul>
                        {ex.works.map((w, j) => (
                          <li key={j}>{w}</li>
                        ))}
                      </ul>
                    </div>
                    <br />
                  </div>
                ))}
              </div>
              <div className="content-two-info">
                <h2 className="flex">
                  <div className="circle"></div> Education
                </h2>
                <br />
                <div>
                  {education.map((e, i) => (
                    <div key={i} className="content-two-wrapper">
                      <div className="content-two-title">
                        <div>
                          <h3>{e.name}</h3>
                          <h4>{e.place}</h4>
                        </div>
                        <div>
                          <h5>{e.cert}</h5>
                        </div>
                      </div>
                      <br />
                    </div>
                  ))}
                  <br />
                  <h2 className="flex">
                    <div className="circle"></div> Skills
                  </h2>
                  <br />
                  <div className="content-two-wrapper">
                    {skills.map((s, i) => (
                      <div key={i}>
                        <div className="content-two-title">
                          <div>
                            <p>{s.name}</p>
                          </div>
                          <div>
                            <h5>{s.percentage}%</h5>
                          </div>
                        </div>
                        <progress
                          className="progress"
                          value={s.percentage}
                          max="100"
                        />
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {selectedTab === 2 && (
        <>
          <div className="content-three">
            <h3 className="content-1-heading">Portfolio</h3>
            <h1>My Works</h1>
            <div className="content-three-info">
              <br />
              <div className="row">
                {portfolioData.map((d, i) => (
                  <div className="card">
                    <img src={d.img} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">{d.name}</h5>
                      <p className="card-text">{d.description}</p>
                      <div className="button-filled">
                        <a href={d.url} target="_blank" rel="noreferrer">
                          Visit Website
                        </a>
                      </div>
                      {d.github && (
                        <div className="button-outlined">
                          <a href={d.github} target="_blank" rel="noreferrer">
                            Github Repo
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Content;
