import { menuData } from "./data";
import "./index.scss";

interface props {
  selectedTab: number;
  setselectedTab: Function;
}

const Menu = ({ selectedTab, setselectedTab }: props) => {
  return (
    <div className="menu">
      {menuData.map((item, index) => (
        <div
          className={`menu-item ${index === selectedTab ? "menu-active" : ""}`}
          key={index}
          onClick={() => setselectedTab(index)}
        >
          {item.icon}
          {index === selectedTab && <h3>{item.name}</h3>}
        </div>
      ))}
    </div>
  );
};

export default Menu;
