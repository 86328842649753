import imeet from "../../assets/proj/imeet.jpg";
import illusion from "../../assets/proj/illusion.jpg";
import request from "../../assets/proj/request.jpg";
import code from "../../assets/proj/code.jpg";
import api from "../../assets/proj/api.jpg";
import jsonformatter from "../../assets/proj/jsonformatter.jpg";
import whatsapp from "../../assets/proj/whatsapp.jpg";
import ipfinder from "../../assets/proj/ipfinder.jpg";
import card from "../../assets/proj/card.jpg";

interface portfolioInterface {
  name: string;
  url: string;
  description: string;
  img: string;
  github?: string;
}

interface exp {
  title: string;
  company: string;
  date: string;
  works: string[];
}

interface edu {
  name: string;
  cert: string;
  place: string;
}

interface skills {
  name: string;
  percentage: number;
}

export const portfolioData: portfolioInterface[] = [
  {
    name: "Illusion Web Developers",
    url: "https://illusionwebdevelopers.com/",
    description:
      "A website for showcasing the service provided and all the information about the client. The website is created with React JS and Node Js.",
    img: illusion,
  },
  {
    name: "IMeet",
    url: "https://imeet.joshuadaniel.me/",
    description:
      "IMeet is an end-to-end video chat application where an unlimited number of users can connect through video calls and text chat. This web app is designed based on Node and Express js.",
    img: imeet,
    github: "https://github.com/ijoshuadaniel/imeet",
  },
  {
    name: "Request Js",
    url: "https://req.joshuadaniel.me/",
    description:
      "Request.js is an online API platform for building and using APIs. This web app is designed using REACT and used Axios as accessing protocols such as request and response.",
    img: request,
    github: "https://github.com/ijoshuadaniel/postman",
  },
  {
    name: "Code Editor",
    url: "https://code.joshuadaniel.me/",
    description:
      "A Javascript based code editor. I have used react and code editor plugin and eval function to output the values.",
    img: code,
    github: "https://github.com/ijoshuadaniel/code",
  },
  {
    name: "Simple Api",
    url: "https://api.joshuadaniel.me/",
    description:
      "Simple API response served from JSON file used PHP for programming this API",
    img: api,
    github: "https://github.com/ijoshuadaniel/simple-api",
  },
  {
    name: "Json Formatter",
    url: "https://json.joshuadaniel.me/",
    description:
      "JSON Formater is an online tool to format unstructured JSON data into a user-friendly readable structure.",
    img: jsonformatter,
    github: "https://github.com/ijoshuadaniel/json-formatter",
  },
  {
    name: "Credit Card Populator",
    url: "https://card.joshuadaniel.me/",
    description:
      "It is a simple credit/debit card auto populator which used in payments using react and masked text plugin.",
    img: card,
    github: "https://github.com/ijoshuadaniel/bestdoc-interview",
  },
  {
    name: "IP Finder",
    url: "https://ip.joshuadaniel.me/",
    description:
      "IP Finder is an online tool that helps us to get location data of an IP address. this web app uses an external API to provide IP location.",
    img: ipfinder,
    github: "https://github.com/ijoshuadaniel/ipfinder",
  },
  {
    name: "Whatsapp Quick Message",
    url: "https://whatsapp.joshuadaniel.me/",
    description:
      "This a simple tool that helps us to send WhatsApp messages without saving number which uses Whatsapp web API.",
    img: whatsapp,
  },
];

export const Experence: exp[] = [
  {
    title: "Module Lead - Full Stack Development",
    company: "MINDTREE LTD",
    date: "2021 - Current",
    works: [
      "Used REACT, NODE JS, TYPESCRIPT, JAVACRIPT, MONGODB, CSS, SCSS and HTML to develop app solutions",
      "Undertook initial concepts and developed project plans.",
      "Delivered performance-driven and user-centric website that met all business requirements.",
      "Designed and Developed mobile apps",
      "Developed user interfaces with modern JavaScript frameworks, HTML5 and CSS3.",
    ],
  },
  {
    title: "Application Developer",
    company: "Divum Corporate Services Private Limited",
    date: "May 2021 - Oct 2021",
    works: [
      "Designed, developed, and implemented software applications for the website based on analyzed requirements and understanding of industry technical standards.",
      "Wrote quality codes to program complete applications.",
      "Planned website development, converting mockups into usable web presence with HTML, JavaScript, AJAX, and JSON coding.",
      "Employed third-party APIs to facilitate seamless access to external, in-app data.",
      "Successfully integrated push notifications into apps.",
    ],
  },
  {
    title: "Developer",
    company: "CIrcle It",
    date: "May 2020 - May 2021",
    works: [
      "Provided front-end website development using WordPress.",
      "Planned website development, converting mockups into usable web presence with HTML, JavaScript, AJAX.",
      "Employed search engine optimization tactics to increase the reach of the targeted audience.",
      "Multi-tasked across multiple functions and roles to generate project results and meet deadlines and organizational expectations.",
      "Established presentation consistency across Chrome, Safari, Firefox, and other common browser interfaces.",
      "Coded websites using HTML, CSS, JavaScript, and jQuery languages.",
    ],
  },
];

export const education: edu[] = [
  {
    name: "SOUTH EAST ASIAN EDUCATION TRUST",
    cert: "SSLC",
    place: "Bangalore, karnataka, India",
  },
  {
    name: "ST.JOSEPH INDIAN PRE UNIVERSITY COLLEGE",
    cert: "PUC",
    place: "Bangalore, karnataka, India",
  },
  {
    name: "SESHADRIPURAM FIRST GRADE COLLEGE",
    cert: "BCOM",
    place: "Bangalore, karnataka, India",
  },
];

export const skills: skills[] = [
  {
    name: "React.Js",
    percentage: 93,
  },
  {
    name: "Node.Js",
    percentage: 85,
  },
  {
    name: "Typescript",
    percentage: 80,
  },
  {
    name: "Express.Js",
    percentage: 84,
  },
  {
    name: "Javascript",
    percentage: 95,
  },
  {
    name: "MongoDb",
    percentage: 75,
  },
  {
    name: "MySQL",
    percentage: 77,
  },
  {
    name: "React Native",
    percentage: 70,
  },
  {
    name: "PHP",
    percentage: 65,
  },
  {
    name: "Flutter and Dart",
    percentage: 60,
  },
];
