import profile from "../../assets/profile.jpg";
import item from "../../assets/svg/item.svg";
import item1 from "../../assets/svg/item-1.svg";
import item2 from "../../assets/svg/item-2.svg";
import item3 from "../../assets/svg/item-3.svg";
import item4 from "../../assets/svg/item-4.svg";
import item5 from "../../assets/svg/item-5.svg";
import item6 from "../../assets/svg/item-6.svg";
import item7 from "../../assets/svg/item-7.svg";
import "./index.scss";

const Header = () => {
  return (
    <div className="header">
      <div className="header-logo">
        <p>DANIEL PAUL JOSHUAH</p>
        <a
          href="https://joshuadaniel.me/Daniel.pdf"
          target="_blank"
          className="resume"
          rel="noreferrer"
        >
          DOWNLOAD CV
        </a>
      </div>
      <div className="svg">
        <img src={item} alt="svg" className="item" />
        <img src={item1} alt="svg" className="item1" />
        <img src={item2} alt="svg" className="item2" />
        <img src={item3} alt="svg" className="item3" />
        <img src={item4} alt="svg" className="item4" />
        <img src={item5} alt="svg" className="item5" />
        <img src={item6} alt="svg" className="item6" />
        <img src={item7} alt="svg" className="item7" />
        <img src={item} alt="svg" className="item8" />
        <img src={item4} alt="svg" className="item9" />
        <img src={item5} alt="svg" className="item10" />
        <img src={item6} alt="svg" className="item11" />
        <img src={item7} alt="svg" className="item12" />
      </div>
      <div className="header-content">
        <div className="header-content_text">
          <p>I'm</p>
          <h1>DANIEL PAUL JOSHUAH</h1>
          <h4>
            A passionate Full Stack Web Developer based In Bangalore, India
          </h4>
        </div>
        <div className="header-content_image">
          <div className="header-content_image_wrapper">
            <img src={profile} alt="profile" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
