import { BsFileText } from "react-icons/bs";
import { RiContactsFill } from "react-icons/ri";
import { FaBriefcase, FaPhoneAlt } from "react-icons/fa";

interface menu {
  id: number;
  icon: React.ReactNode;
  name: string;
}

export const menuData: menu[] = [
  {
    id: 1,
    icon: <BsFileText />,
    name: "About",
  },

  {
    id: 2,
    icon: <RiContactsFill />,
    name: "Resume",
  },
  {
    id: 3,
    icon: <FaBriefcase />,
    name: "Portfolio",
  },
  // {
  //   id: 4,
  //   icon: <FaPhoneAlt />,
  //   name: "Contact",
  // },
];
