import { useState } from "react";
import "./app.scss";
import Content from "./component/content";
import Header from "./component/header";
import Menu from "./component/menu";

const App = () => {
  const [selectedTab, setselectedTab] = useState(0);

  return (
    <div className="portfolio">
      <Header />
      <Menu selectedTab={selectedTab} setselectedTab={setselectedTab} />
      <Content selectedTab={selectedTab} />
    </div>
  );
};

export default App;
